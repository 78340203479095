<template>
  <div>
    <div class="achievement_top_area">
      <div class="achievement_top_inner txt-white w1400">
        <small class="txt-white">
          首頁 - <span>{{nowPageData.pageName}}</span>
        </small>
        <h2 class="h1 txt-bold">{{nowPageData.pageNameEn}}</h2>
        <h5 class="sub_title txt-bold">
          {{nowPageData.pageName}}
        </h5>
      </div>
    </div>

    <!-- <div
      class="top_point main_padding"
      v-if="nowPageData.infoPoint.length > 0">
        <div class="pointbox w1400">
          <h5 class="sub_title txt-bold">
            項目特點
          </h5>
          <ul class="point_list">
            <li
              v-for="(item, i) in nowPageData.infoPoint"
              :key="`achievementPointList${i}`">
                <p>
                  <span class="txt-theme">・</span>
                  {{item}}
                </p>
            </li>
          </ul>
        </div>
    </div> -->

    <div
      class="introduction main_padding"
      v-if="nowPageData.introduction">
        <div class="w1400">
          <small style="display: block; margin-bottom: 20px;">
            首頁 - <span class="txt-gray">{{nowPageData.pageName}}</span>
          </small>
          <div class="introduction_box">
            <div class="introduction_title">
              <img
                src="@/assets/img/achievement/02_arrow.svg"
                class="prev"
                v-if="nowPageData.introduction.length > 1"
                @click="changePrevIndex">
              <h6>{{nowPageData.introduction[nowShowIndex].title}}</h6>
              <img
                src="@/assets/img/achievement/02_arrow.svg"
                class="next"
                v-if="nowPageData.introduction.length > 1"
                @click="changeNextIndex">
            </div>
            <div class="introduction_point">
              <div
                class="pointbox"
                v-for="(item, i) in nowPageData.introduction[nowShowIndex].point"
                :key="`introductionPoint${i}`">
                  <p>{{item.name}}</p>
                  <p>{{item.info}}</p>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div
      class="achievement_info main_padding"
      v-if="
        nowPageData.introduction
        && nowPageData.introduction[nowShowIndex].info
      ">
        <div class="inner w1400">
          <div class="itembox">
            <div
              class="item"
              v-for="(item, i) in nowPageData.introduction[nowShowIndex].info"
              :key="`achievementInfoItem${i}`">
              <!-- <h2 class="h1 border_title txt-right main_padding">
                <span class="txt-bold">{{item.subtitle}}</span>
              </h2> -->
              <h5 class="sub_title txt-bold">
                {{item.title}}
              </h5>
              <p>{{item.infoText}}</p>
              <img :src="item.infoPic" alt="">
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AchievementInfo',
  props: ['nowPageData'],
  components: {
  },
  data() {
    return {
      nowShowIndex: 0,
    };
  },
  watch: {
    $route() {
      this.nowShowIndex = 0;
    },
  },
  methods: {
    changeNextIndex() {
      if (this.nowShowIndex < this.nowPageData.introduction.length - 1) {
        this.nowShowIndex += 1;
      } else {
        this.nowShowIndex = 0;
      }
    },
    changePrevIndex() {
      if (this.nowShowIndex > 0) {
        this.nowShowIndex -= 1;
      } else {
        this.nowShowIndex = this.nowPageData.introduction.length - 1;
      }
    },
    // scrollTo(elem) {
    //   console.log(elem, window);
    //   window.scrollTo({
    //     top: elem.offsetTop - 20,
    //     behavior: 'smooth',
    //   });
    // },
  },
};
</script>
